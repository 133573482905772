<template>
  <div class="sf-product-card">
    <div
      class="sf-product-card__image-wrapper"
      @click="sendGtmEvent"
      @mouseover="() => { isHovered = true; }"
      @mouseleave="() => { isHovered = false; }"
    >
      <span
        v-if="badge && badge.label !== null"
        :key="badge.label"
        class="sf-product-card__badges"
        :style="{color: badge.textColor, 'background-color': badge.bgColor}"
      >
        {{ badge.label }}
      </span>
      <no-ssr>
        <div class="rating-data" v-if="reviewsSummary.rating">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="fill-current w-6 h-6"
            viewBox="3 0 20 20"
            style="color: #f6e05e"
            fill="currentColor"
          >
            <path
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371
              1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54
              1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1
              1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
            />
          </svg>
          <label v-if="reviewsSummary.rating"><b>{{ reviewsSummary.rating.toFixed(1) }}</b></label>
        </div>
      </no-ssr>
      <SfLink :link="link" class="sf-product-card__link">
        <template v-if="Array.isArray(image) && !useStandardImages">
          <AImage
            v-for="(picture, key) in image.slice(0, 2)"
            :key="key"
            class="sf-product-card__picture"
            :src="picture"
            :alt="title"
            placeholder="/assets/placeholder.jpg"
            loading="lazy"
            :height="imageHeight"
          />
        </template>
        <AImage
          v-else-if="!useStandardImages"
          :src="productHoverImage"
          :alt="title"
          placeholder="/assets/placeholder.jpg"
          loading="lazy"
          :height="imageHeight"
        />
        <div class="o-product-card__standard-image-wrapper" v-else>
          <img
            class="o-product-card__standard-image"
            :src="productHoverImage"
            :alt="title"
            :height="imageHeight"
            :width="imageWidth"
            loading="lazy"
          >
        </div>
      </SfLink>
      <SfBadge
        v-if="badgeLabel"
        class="sf-product-card__badge"
        :class="badgeColorClass"
      >
        {{ badgeLabel }}
      </SfBadge>
    </div>
    <div class="sf-product-card__title-wrapper" @click="sendGtmEvent">
      <slot name="title" v-bind="{ title, link }">
        <SfLink :link="link" class="sf-product-card__link">
          <h3 class="sf-product-card__title" :title="title">
            {{ title }}
          </h3>
        </SfLink>
      </slot>
    </div>
    <div
      v-if="typeof scoreRating === 'number'"
      class="sf-product-card__reviews"
    >
      <AProductRating
        :show-reviews-count="true"
        :score="reviewsSummary.rating"
        class="o-product-card__rating"
        :reviews-count="reviewsSummary.count"
        :should-link-to-reviews="false"
      />
    </div>
    <div class="sf-product-card__buy-button">
      <SfButton
        class="color-secondary sf-button--full-width"
        :class="{
          'button-busy': isCartBusyServerSkip,
          'button-busy-centered': isCartBusyServerSkip && btnClicked
        }"
        @click="buyProduct"
        :disabled="addToCartDisabled"
      >
        <AIcon
          v-if="showPrice"
          size="xs"
          icon="cart"
          color="currentColor"
          class="icon"
        />
        <div v-if="isCartBusyServerSkip && btnClicked" class="button-content-wrapper button-content-wrapper--spinner">
          <ASpinner :size="20.75" />
        </div>
        <slot v-if="showPrice" name="price" v-bind="{ specialPrice, regularPrice }">
          <SfPrice
            v-if="(finalPrice) && !showVerificationIcon"
            class="sf-product-card__price"
            :regular="formatPrice(finalPrice)"
          />
          <div v-else-if="showVerificationIcon" class="button-content-wrapper button-content-wrapper--spinner">
            <SfIcon
              icon="check"
              size="2rem"
              color="white"
            />
          </div>
          <div v-else>
            <div class="sf-price sf-product-card__price">
              <span class="sf-price__regular">{{ $t('Sold Out') }}</span>
            </div>
          </div>
          <no-ssr>
            <div class="strikethrough-price" v-if="showPrice && hasStrikethroughPrice">
              {{ regularPrice }}
            </div>
          </no-ssr>
        </slot>
        <div v-if="showPrice && !hasStrikethroughPrice" class="btn-placeholder" />
      </SfButton>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { colorsValues as SF_COLORS } from '@storefront-ui/shared/variables/colors';

import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfPrice from '@storefront-ui/vue/src/components/atoms/SfPrice/SfPrice.vue';
import SfLink from '@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue';
import SfBadge from '@storefront-ui/vue/src/components/atoms/SfBadge/SfBadge.vue';
import ASpinner from '~/theme/components/atoms/a-spinner';
import { Logger } from '@vue-storefront/core/lib/logger'
import AProductRating from '~/theme/components/atoms/a-product-rating';
import AImage from '~/theme/components/atoms/a-image'
import AIcon from '~/theme/components/atoms/a-icon.vue'
import TrackingMixin from '~/theme/mixins/tracking/tracking.ts'
import { Microcart } from '@vue-storefront/core/modules/cart/components/Microcart.ts'
import { isServer } from '@vue-storefront/core/helpers'
import { formatPrice, getBadgesToDisplay } from '~/theme/helpers';
import NoSSR from 'vue-no-ssr';

export default {
  name: 'OProductCard',
  components: {
    AIcon,
    AProductRating,
    ASpinner,
    SfPrice,
    SfIcon,
    AImage,
    SfLink,
    SfBadge,
    SfButton,
    'no-ssr': NoSSR
  },
  mixins: [TrackingMixin, Microcart],
  props: {
    /**
     * Product image
     * It should be an url of the product
     */
    image: {
      type: [Array, Object, String],
      default: ''
    },
    /**
     * Product hover image
     */
    hoverImage: {
      type: String,
      default: ''
    },
    /**
     * Product image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: 286
    },
    /**
     * Product image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: 286
    },
    /**
     * Badge label
     */
    badgeLabel: {
      type: String,
      default: ''
    },
    sku: {
      type: String,
      default: ''
    },
    parentSku: {
      type: String,
      default: ''
    },
    /**
     * Badge color
     * It can be according to our standard colors, or legitimate CSS color such as `#fff`, `rgb(255,255,255)`), and `lightgray` or nothing.
     * Standard colors: `primary`, `secondary`, `white`, `black`, `accent`, `green-primary`, `green-secondary`, `gray-primary`, `gray-secondary`, `light-primary`, `light-secondary`, `pink-primary`, `pink-secondary`, `yellow-primary`, `yellow-secondary`, `blue-primary`, `blue-secondary`.
     */
    badgeColor: {
      type: String,
      default: ''
    },
    /**
     * Product title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Link to product page
     */
    link: {
      type: [String, Object],
      default: ''
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined
    },
    /**
     * Product rating
     */
    scoreRating: {
      type: [Number, Boolean],
      default: false
    },
    /**
     * Product reviews count
     */
    reviewsCount: {
      type: [Number, Boolean],
      default: false
    },
    /**
     * Maximum product rating
     */
    maxRating: {
      type: [Number, String],
      default: 5
    },
    /**
     * Product regular price
     */
    regularPrice: {
      type: [Number, String],
      default: null
    },
    /**
     * Product special price
     */
    specialPrice: {
      type: [Number, String],
      default: null
    },
    finalPrice: {
      type: [Number, String],
      default: null
    },
    /**
     * Wish list icon
     * This is the default icon for product not yet added to wish list.
     * It can be a icon name from our icons list, or array or string as SVG path(s).
     */
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart'
    },
    /**
     * Wish list icon for product which has been added to wish list
     * This is the icon for product added to wish list. Default visible on mobile. Visible only on hover on desktop.
     * It can be a icon name from our icons list, or array or string as SVG path(s).
     */
    isOnWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill'
    },
    /**
     * Status of whether product is on wish list or not
     */
    isOnWishlist: {
      type: Boolean,
      default: false
    },
    /**
     * addToCartDisabled status of whether button is disabled when out of stock
     */
    addToCartDisabled: {
      type: Boolean,
      default: false
    },
    useStandardImages: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: String,
      default: 'simple'
    },
    badges: {
      type: Array,
      default () {
        return []
      }
    },
    badgesSortOrder: {
      type: Array,
      default () {
        return []
      }
    },
    percentageOff: {
      type: Number,
      default: null
    },
    gtmCustomDimensions: {
      type: Object,
      default: null
    },
    gtmItemList: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.initTrackingScripts()
    this.getBadge()
  },
  data () {
    return {
      isHovered: false,
      isAddingToCart: false,
      isColorPickerOpen: false,
      showVerificationIcon: false,
      btnClicked: false,
      badge: {}
    };
  },
  computed: {
    productHoverImage () {
      return this.isHovered ? (this.hoverImage || this.image) : this.image;
    },
    isSFColors () {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass () {
      return this.isSFColors ? `${this.badgeColor.trim()}` : '';
    },
    currentWishlistIcon () {
      return this.isOnWishlist ? this.isOnWishlistIcon : this.wishlistIcon;
    },
    reviewsSummary () {
      const summaryMock = {
        count: 0,
        rating: 0,
        distribution: [ 0, 0, 0, 0, 0 ],
        mock: true
      }
      return this.$store.state['stamped-io'].summary[this.parentSku] || summaryMock
    },
    ...mapGetters({
      isCartBusy: 'cart/getCartProcessingStatus',
      attributesByCode: 'attribute/getAttributeListByCode'
    }),
    isCartBusyServerSkip () {
      if (isServer) return false
      return this.isCartBusy
    },
    showPrice () {
      return !this.isCartBusyServerSkip || (!this.btnClicked && this.isCartBusyServerSkip);
    },
    hasStrikethroughPrice () {
      return this.finalPrice < this.regularPrice
    }
  },
  methods: {
    formatPrice (price) {
      return formatPrice(price)
    },
    toggleIsOnWishlist () {
      this.$emit('click:wishlist', !this.isOnWishlist);
    },
    onAddToCart (event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit('click:add-to-cart');
    },
    async buyProduct () {
      this.btnClicked = true
      if (this.typeId === 'bundle') {
        this.$router.push(this.link)
      } else if (this.typeId !== 'simple') {
        // this.$router.push(this.link)
        await this.$store.dispatch('product/loadProduct', { parentSku: this.parentSku, childSku: this.sku });
        await this.$store.dispatch('ui/toggleProductQuickSwitcher')
        this.btnClicked = false
      } else {
        const product = await this.$store.dispatch('product/single', { options: { sku: this.sku } });
        const totalPrice = this.totals.find(item => item.code === 'grand_total')?.value

        this.handleAddingToCart({
          cartProducts: this.productsInCart,
          totalPrice: totalPrice,
          addedProduct: product,
          checkoutUrl: 'checkout'
        })
        await this.$store.dispatch('cart/addItem', {
          productToAdd: product
        });
        this.$store.dispatch('ui/toggleSidebar', false)
        this.$store.dispatch('ui/toggleMicrocart', true)
        this.showVerificationIcon = true
        this.btnClicked = false
        setTimeout(() => {
          this.showVerificationIcon = false
        }, 2000);
      }
    },
    async sendGtmEvent () {
      let product = await this.$store.dispatch('product/single', { options: { sku: this.sku } });
      if (this.gtmCustomDimensions) {
        product.gtmCustomDimensions = this.gtmCustomDimensions
      }
      this.$bus.$emit('product-card-click', product, this.gtmItemList)
    },
    async getBadge () {
      this.badge = getBadgesToDisplay(this.badges, this.attributesByCode.badges.options, this.badgesSortOrder, this.getLabelOfSpecialBadge.bind(this))[0]
    },
    isSpecialBadge (badgeRawLabel) {
      return ['discount_amount', 'discount_percent'].includes(badgeRawLabel)
    },
    getLabelOfSpecialBadge (badgeRawLabel) {
      switch (badgeRawLabel) {
        case 'discount_amount':
          let priceOff = this.regularPrice - this.finalPrice
          if (priceOff === 0) return null
          return this.$t('Save {discount}', { discount: formatPrice(priceOff) })
        case 'discount_percent':
          // We can't calculalate % off on product card because we don't have access to children
          if (this.finalPrice) {
            const sale = Math.floor(((this.regularPrice - this.finalPrice) / this.regularPrice) * 100)
            if (sale === 0) return null
            return this.$t('Save {discount}', { discount: sale }) + '%'
          }
          return this.$t('Sold Out')
        default:
          return null
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.sf-product-card__badges{
  position: absolute;
  margin: var(--spacer-xs);
  font-weight: var(--font-weight--semibold);
  top: 0;
  right: 0;
  z-index: 7;
  font-size: var(--font-size--xs);
  padding: var(--spacer-xs) var(--spacer-sm);
  border-radius: var(--spacer-base);
  @include for-mobile {
    margin: var(--spacer-xs)
  }
}
.o-product-card {
  &__rating {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: var(--rating-height);
    ::v-deep {
      .sf-rating {
        justify-content: center;
      }
    }
  }
  &__standard-image-wrapper {
    position: relative;
    display: block;
  }
  &__standard-image {
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 7px;
  }
}
.sf-product-card {
  display: var(--display-flex) !important;
  flex-direction: column;
  padding: var(--spacer-sm) var(--spacer-xs);
  --product-card-max-width: 19rem;
  --product-card-wishlist-icon-opacity: 1;
  --product-card-wishlist-icon-top: var(--spacer-sm);
  --product-card-wishlist-icon-right: var(--spacer-sm);
  --product-card-title-margin: var(--spacer-xs) 0 0;
  --product-card-title-font-size: var(--font-size--sm);
  --price-regular-margin: 0 auto;
  @include for-mobile {
    --product-card-max-width: 50vw;
    &:hover {
      --product-card-box-shadow: none;
    }
  }
  @include for-desktop {
    --product-card-padding: var(--spacer-sm) var(--product-sides-padding, --spacer-sm) var(--spacer-lg);
    --product-card-title-margin: var(--spacer-xs) 0 0 0;
    --product-card-title-font-size: var(--font-size--base);
    &:hover {
      --product-card-box-shadow: none;
    }
  }
  &__image-wrapper {
    border-radius: 7px;
    overflow: hidden;
  }
  &__badge {
    --product-card-badge-top: var(--spacer-sm);
    --badge-background: var(--c-danger);
    --badge-padding: var(--spacer-xs) var(--spacer-sm);
  }
  &__title-wrapper {
    --link-text-decoration: none;
    display: flex;
    width: 100%;
  }
  &__title {
    text-align: left;
    margin-bottom: var(--spacer-xs);
    font-family: var(--font-family--primary);
    font-size: var(--font-size--sm);
    text-transform: uppercase;
  }
  &__wishlist-icon-wrapper {
    z-index: 0;
    display: flex;
    flex-direction: row-reverse;
    margin-top: -75%;
    margin-right: -75%;
  }
  &__wishlist-icon {
    width: 18px;
    position: static;
    justify-content: flex-end;
    align-self: flex-end;
    margin-bottom: var(--spacer-2xs);
    --icon-color: var(--c-dark);
    &:hover, &.on-wishlist {
      --icon-color: var(--c-primary);
    }
  }
  &__link {
    text-align: center;
    .a-image--wrapper {
      display: block;
    }
  }
  &__price {
    --product-card-margin: var(--spacer-xs) var(--spacer-xs);
  }
  &__reviews {
    justify-content: flex-start;
    margin-top: auto;
    img {
      display: block;
    }
    div.product {
      &__rating {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: start;
        gap: var(--spacer-xs);
      }
      &__count {
        margin-top: 0;
      }
    }
  }
  &__buy-button {
    --price-regular-color: currentColor;
    --price-special-color: currentColor;
    --price-regular-font-size: var(--font-size--lg);
    --price-regular-font-family: var(--font-family--primary);
    --price-regular-font-weight: var(--font-weight--bold);
    margin-top: var(--spacer-xs);
    .sf-button {
    --button-padding: 0 var(--spacer-xs);
      justify-content: space-between;
      text-transform: unset;
      min-height: 2.75rem;
      padding: var(--spacer-xs) var(--spacer-sm);
      border-radius: var(--spacer-xl);
      .sf-icon-path {
        path {
          fill: var(--c-category-add-to-cart-text-color);
        }
      }
      span, .strikethrough-price {
        color: var(--c-category-add-to-cart-text-color);
      }
      .sf-price {
        margin: 0;
      }
      @include for-mobile {
        svg {
          height: 20px;
          width: auto;
        }
        .sf-price__regular, .strikethrough-price {
          font-size: var(--font-size--sm);
        }
      }
      @include for-desktop {
        --button-padding: var(--spacer-2xs) var(--spacer-sm);
        min-height: 3.25rem;
      }
    }
    .btn-placeholder {
      width: 1.4rem;
    }

    .strikethrough-price {
      text-decoration: line-through;
      text-align: right;
    }
  }
  .a-image--placeholder,
  .a-image {
    max-width: 100%;
  }
}
.sf-product-card__buy-button .button-busy {
  --loader-spinner-stroke: currentColor;
  opacity: 0.7;
  --button-background: var(--c-secondary);
  &-centered {
    justify-content: center;
  }
}
.minicart-upsell-products {
  .rating-data {
    position: absolute;
    top: var(--spacer-xs);
    left: var(--spacer-xs);
    width: auto;
    padding: var(--spacer-2xs) var(--spacer-xs);
    background: var(--c-white);
    z-index: 9;
    border-radius: var(--spacer-lg);
    font-size: var(--font-size--xs);
    box-shadow: 0px 0px var(--spacer-xs) -1px var(--c-primary);
    display: flex;
    align-items: center;
    svg {
      width: var(--spacer-sm);
      path {
        fill: var(--c-warning)
      }
    }
  }
  .sf-product-card__reviews {
    display: none
  }
}
.rating-data {
  display: none
}
</style>
